




















































































import Vue from "vue";
import axios from "axios";
import { User } from "@/types/user";
import { Token } from "@/types/token";

const totpLength = 6;

export default Vue.extend({
  name: "LoginComponent",

  data: () => ({
    loginCredentials: {
      filledinUser: "",
      filledinPassword: "",
      filledinTOTP: "",
    },
    valid: true,
    error: undefined,
    headers: "Content-Type=application/x-www-form-urlencoded",
    emailRules: [
      (v: string) => !!v || "E-mailadres is verplicht",
      (v: string) => /.+@.+\..+/.test(v) || "Voer een geldig E-mailadres in",
    ],
    passwordRules: [(v: string) => !!v || "Wachtwoord is verplicht"],
    totpRules: [
      (v: string) =>
        !!v ||
        "De eenmalige code uit de Authenticator-app is verplicht, zorg dat deze op tijd wordt ingevoerd",
      (v: string) =>
        (v && v.length === totpLength) ||
        "De eenmalige code uit de Authenticator-app bestaat uit 6 cijfers ",
    ],
  }),
  methods: {
    Login() {
      this.error = undefined;
      axios
        .post(
          `${this.$store.state.APIurl}/user/login`,
          `username=${this.loginCredentials.filledinUser}&password=${this.loginCredentials.filledinPassword}${this.loginCredentials.filledinTOTP}`
        )
        .then((response) => {
          const response_data: Token = response.data as unknown as Token;
          const access_token: string = response_data.access_token;
          sessionStorage.setItem("jwt", access_token);
          this.$store.commit("changeUserToken", access_token);
          this.verifyAndRedirect();
        })
        .catch((error) => {
          this.error = error;
        });
    },
    verifyAndRedirect() {
      axios
        .get(`${this.$store.state.APIurl}/user/login/verifieer`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.user.token}`,
          },
        })
        .then((response) => {
          const response_data: User = response.data;
          this.$store.commit("changeUserToken", this.$store.state.user.token);
          this.$store.commit("changeUserEmail", response_data.email);
          this.$store.commit("changeUserAdmin", response_data.admin);
          this.$store.commit("changeUserAuthenticated", true);
        })
        .catch((error) => {
          sessionStorage.removeItem("jwt");
          this.$store.commit("changeUserToken", "");
          this.$store.commit("changeUserEmail", "");
          this.$store.commit("changeUserAdmin", false);
          this.$store.commit("changeUserAuthenticated", false);
        });
      this.$router.push("/");
    },
  },
});
